import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { FaRocket, FaTruck } from "react-icons/fa"
import { FaRegHandshake, FaRegMoneyBill1 } from "react-icons/fa6"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import EmailService from "../../services/EmailService"

export default function AggregateScreen() {
    useEffect(() => {
        document.title = "Logplace - Nossos Serviços"
    }, [])

    const [headerFixed, setHeaderFixed] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    // Estado para os campos do formulário
    interface FormData {
        name: string
        email: string
        phone: string
        vehicleType: string
        location: string
        additionalInfo: string
        receiveUpdates: boolean
    }

    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        phone: "",
        vehicleType: "",
        location: "",
        additionalInfo: "",
        receiveUpdates: false
    })

    // Estado para mensagens de erro
    const [errors, setErrors] = useState<{ [key: string]: string }>({})

    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/rastreamento")
    }

    const headerFixedTrue = () => {
        setHeaderFixed(true)
    }

    const headerFixedFalse = () => {
        setHeaderFixed(false)
    }

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value
        }))
    }

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {}
        if (!formData.name) newErrors.name = "Nome é obrigatório."
        if (!formData.email) newErrors.email = "Email é obrigatório."
        if (!formData.phone) newErrors.phone = "Telefone é obrigatório."
        if (!formData.vehicleType)
            newErrors.vehicleType = "Tipo de veículo é obrigatório."
        if (!formData.location)
            newErrors.location = "Unidade de trabalho é obrigatória."

        return newErrors
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        const validationErrors = validateForm()
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
            toast.error("Preencha todos os campos corretamente.", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return
        }

        try {
            const response = await EmailService.sendEmail(
                "Quero ser um motorista agregado",
                `Nome: ${formData.name}\nEmail: ${formData.email}\nTelefone: ${formData.phone}\nTipo de veículo: ${formData.vehicleType}\nLocal de trabalho: ${formData.location}\nInformações adicionais: ${formData.additionalInfo}\nDeseja receber informativos: ${formData.receiveUpdates}`,
                "agregado",
                new File(
                    [
                        JSON.stringify({
                            name: formData.name,
                            email: formData.email,
                            phone: formData.phone,
                            vehicleType: formData.vehicleType,
                            location: formData.location,
                            additionalInfo: formData.additionalInfo,
                            receiveUpdates: formData.receiveUpdates
                        })
                    ],
                    "data.json",
                    { type: "application/json" }
                )
            )

            if (response.status !== 200) {
                throw new Error("Erro ao enviar os dados.")
            }
            toast.error("Dados enviados com sucesso!.", {
                style: {
                    backgroundColor: "green",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            setFormData({
                name: "",
                email: "",
                phone: "",
                vehicleType: "",
                location: "",
                additionalInfo: "",
                receiveUpdates: false
            })
            setErrors({})
        } catch (error) {
            console.error(error)
            toast.error("Erro ao enviar os dados.", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
        }
    }

    return (
        <>
            <Helmet>
                <title>Trabalhe Conosco</title>
                <meta
                    name="description"
                    content="Oferecemos soluções logísticas sob medida para sua operação. Fulfillment, B2B, B2C com inteligência e tecnologia."
                />
                <meta
                    name="keywords"
                    content="logística, fulfillment, B2C, B2B, transporte, soluções logísticas, entregas, e-commerce"
                />
                <meta name="author" content="LogPlace" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta property="og:title" content="Nossos Serviços" />
                <meta
                    property="og:description"
                    content="Somos líderes em soluções logísticas para o pequeno, médio e-commerce e grandes operações de varejo."
                />
                <meta property="og:url" content="https://logplace.com.br" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div
                className="relative flex flex-col flex-1 w-full h-screen xl:overflow-y-auto"
                onScroll={(event) => {
                    if (event.currentTarget.scrollTop > 10) {
                        setHeaderFixed(true)
                    } else {
                        setHeaderFixed(false)
                    }

                    if (event.currentTarget.scrollTop > 500) {
                        setIsVisible(true)
                    } else {
                        setIsVisible(false)
                    }
                }}
            >
                <div className="inset-0 w-full min-h-screen bg-gradient-to-b from-white via-secondary-1 via-70% via-secondary-2 via-35% to-primary to-80% z-0">
                    <ToastContainer
                        position="bottom-right"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        icon={false}
                        limit={1}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Header
                        background={headerFixed ? " " : ""}
                        headerFixedTrue={headerFixedTrue}
                        headerFixedFalse={headerFixedFalse}
                    />
                    <div className="h-[40rem] sm:h-[35rem] md:h-[40rem] lg:h-[35rem] xl:h-[30rem] justify-center items-center flex flex-col sm:px-[10rem] md:px-[13rem] xl:px-[25rem] 2xl:px-[29rem]">
                        <h1 className="text-6xl font-bold text-center text-black">
                            Nós somos a LogPlace
                        </h1>
                        <p className="mt-12">
                            <p className="text-xl font-semibold text-center text-black">
                                Somos um empresa com mais de 18 anos de mercado
                                especializados em logística na região Nordeste
                                do Brasil, e acreditamos que o elo mais
                                importante do nosso sucesso está em nosso time
                                de colaboradores
                            </p>
                            <h1 className="mt-5 text-xl font-semibold text-center text-black">
                                Através de nossas mais de 11 unidades, buscamos
                                sempre a valorização dos nossos funcionários, o
                                fortalecimento da cultura e o fomento de novas
                                ideias buscando criar um ambiente positivo para
                                todos
                            </h1>
                        </p>
                    </div>
                    <div className="h-[100rem] sm:h-[90rem] md:h-[80rem] lg:h-[30rem] flex flex-col space-y-4 lg:space-y-10  justify-center items-center lg:space-x-5 xl:px-44">
                        <div className="flex flex-col items-center justify-center w-full h-full p-4 mt-4 space-y-4">
                            <div className="flex items-center justify-center">
                                <h1 className="text-3xl font-medium sm:text-4xl text-primary">
                                    O que nos torna diferentes?
                                </h1>
                            </div>
                            <div className="flex flex-col justify-between w-full h-full p-4 space-y-4 lg:flex-row md:w-2/3 lg:w-full bg-primary lg:space-y-0 lg:space-x-4 rounded-3xl">
                                <div className="h-full lg:w-1/4">
                                    <div className="flex flex-col h-full p-2 text-center bg-white rounded-3xl justify-evenly">
                                        <div className="flex items-center justify-center">
                                            <FaRegMoneyBill1
                                                color="#1D8D9B"
                                                size={60}
                                            />
                                        </div>
                                        <h1 className="text-2xl font-semibold text-secondary-1">
                                            Pagamento
                                        </h1>
                                        <p>
                                            Condições de pagamento facilitadas e
                                            com total transparência.
                                        </p>
                                    </div>
                                </div>
                                <div className="h-full lg:w-1/4">
                                    <div className="flex flex-col h-full p-2 text-center bg-white rounded-3xl justify-evenly">
                                        <div className="flex items-center justify-center">
                                            <FaTruck
                                                color="#1D8D9B"
                                                size={60}
                                            />
                                        </div>
                                        <h1 className="text-2xl font-semibold text-secondary-1">
                                            Monitoramento
                                        </h1>
                                        <p>
                                            Segurança de viajar com
                                            monitoramento 24h
                                        </p>
                                    </div>
                                </div>
                                <div className="h-full lg:w-1/4">
                                    <div className="flex flex-col h-full p-2 text-center bg-white rounded-3xl justify-evenly">
                                        <div className="flex items-center justify-center">
                                            <FaRegHandshake
                                                color="#1D8D9B"
                                                size={60}
                                            />
                                        </div>
                                        <h1 className="text-2xl font-semibold text-secondary-1">
                                            Parceria
                                        </h1>
                                        <p>
                                            Benefícios exclusivos e bônus
                                            especiais como incentivo
                                        </p>
                                    </div>
                                </div>
                                <div className="h-full lg:w-1/4">
                                    <div className="flex flex-col h-full p-2 text-center bg-white rounded-3xl justify-evenly">
                                        <div className="flex items-center justify-center">
                                            <FaRocket
                                                color="#1D8D9B"
                                                size={60}
                                            />
                                        </div>
                                        <h1 className="text-2xl font-semibold text-secondary-1">
                                            Oportunidade
                                        </h1>
                                        <p>
                                            Você que já está em uma operação
                                            dedicada, oferecemos a oportunidade
                                            de uma renda extra conosco
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-[44rem] sm:h-[50rem] md:h-[75rem] lg:h-[50rem] xl:h-[43rem] flex justify-center items-center">
                        <div className="flex flex-row items-center justify-center w-full h-full p-4">
                            <div className="flex flex-col w-full h-full p-4 rounded-lg shadow-sm lg:w-2/3 2xl:w-1/2 bg-primary shadow-gray-100">
                                <div className="flex items-center justify-center p-4 mb-4">
                                    <h1 className="mb-4 text-2xl font-bold text-white">
                                        Faça parte do nosso sucesso!
                                    </h1>
                                </div>
                                <form
                                    className="flex flex-col justify-center space-y-4"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="flex flex-row h-12">
                                        <input
                                            className="w-full px-4 py-2 rounded-md"
                                            placeholder="Nome Completo"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="flex flex-row h-12 space-x-4">
                                        <input
                                            className="w-1/2 px-4 py-2 rounded-md"
                                            placeholder="Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        <input
                                            className="w-1/2 px-4 py-2 rounded-md"
                                            placeholder="DDD+Numero (Whatsapp)"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="flex flex-row h-12 space-x-4">
                                        <select
                                            className="w-1/2 px-2 py-2 rounded-md"
                                            name="vehicleType"
                                            value={formData.vehicleType}
                                            onChange={handleChange}
                                        >
                                            <option value="">
                                                Tipo de Veículo
                                            </option>
                                            <option value="Toco">Toco</option>
                                            <option value="Truck">Truck</option>
                                            <option value="VAN">VAN</option>
                                            <option value="V.U.C.">
                                                V.U.C.
                                            </option>
                                            <option value="Utilitário">
                                                Utilitário
                                            </option>
                                        </select>

                                        <select
                                            className="w-1/2 px-2 py-2 rounded-md"
                                            name="location"
                                            value={formData.location}
                                            onChange={handleChange}
                                        >
                                            <option value="">
                                                Qual unidade deseja trabalhar?
                                            </option>
                                            <option value="Sao Luis - MA">
                                                Sao Luis - MA
                                            </option>
                                            <option value="Teresina - PI">
                                                Teresina - PI
                                            </option>
                                            <option value="Fortaleza - CE">
                                                Fortaleza - CE
                                            </option>
                                            <option value="Parnamirim - RN">
                                                Parnamirim - RN
                                            </option>
                                            <option value="João Pessoa - PB">
                                                João Pessoa - PB
                                            </option>
                                            <option value="Jaboatão dos Guararapes - PE">
                                                Jaboatão dos Guararapes - PE
                                            </option>
                                            <option value="Maceió - AL">
                                                Maceió - AL
                                            </option>
                                            <option value="Nossa Sra. do Socorro - SE">
                                                Nossa Sra. do Socorro - SE
                                            </option>
                                            <option value="Simões Filho - BA">
                                                Simões Filho - BA
                                            </option>
                                            <option value="Feira de Santana - BA">
                                                Feira de Santana - BA
                                            </option>
                                            <option value="Vitória da Conquista - BA">
                                                Vitória da Conquista - BA
                                            </option>
                                        </select>
                                    </div>
                                    <div className="flex flex-row h-24">
                                        <textarea
                                            className="w-full p-2 rounded-md resize-none"
                                            placeholder="Outras informações"
                                            name="additionalInfo"
                                            value={formData.additionalInfo}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="flex flex-row items-center h-12 space-x-2">
                                        <input
                                            className="h-8 text-white file:rounded-lg file:border-none file:px-4 file:py-2 file:font-semibold file:bg-white"
                                            type="checkbox"
                                            name="receiveUpdates"
                                            checked={formData.receiveUpdates}
                                            onChange={handleChange}
                                        />
                                        <p className="text-white">
                                            Desejo receber informativos da
                                            Logplace
                                        </p>
                                    </div>
                                    <div className="w-full h-12">
                                        <button
                                            type="submit"
                                            className="w-full h-full bg-green-400 rounded-lg"
                                        >
                                            <h1 className="font-semibold text-white">
                                                QUERO SER UM MOTORISTA AGREGADO
                                            </h1>
                                        </button>
                                    </div>
                                    <div className="w-full h-12 space-x-2">
                                        <p className="text-center text-white">
                                            Conforme estabelecido na Lei LGPD
                                            3218/2019, informamos que ao enviar
                                            os seus dados pessoais você autoriza
                                            a utilização dos dados fornecidos
                                            para utilização em processos
                                            internos.
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
