import YouTube from "react-youtube"
import "./YoutubePlayer.css"

const YouTubePlayer = () => {
    const videoId = "rRxLPHkIT0A" // Substitua pelo ID do vídeo do YouTube que você deseja exibir

    const onPlayerReady = (event: any) => {
        // Acesse o player através do objeto event
        event.target.pauseVideo()
    }

    const opts = {
        height: "720",
        width: "1280",
        playerVars: {
            autoplay: 0
        }
    }

    return (
        <div className="youtube-container justify-center items-center flex">
            <YouTube
                videoId={videoId}
                opts={opts}
                onReady={onPlayerReady}
                className="youtube-player"
            />
        </div>
    )
}

export default YouTubePlayer
