import { useEffect, useRef, useState } from "react"
import { AiOutlineGlobal } from "react-icons/ai"
import { BsPerson, BsSuitcaseLg, BsTruck } from "react-icons/bs"
import { FaCheck, FaRegHandshake } from "react-icons/fa"
import { GrAchievement, GrUserAdd } from "react-icons/gr"

import { Helmet } from "react-helmet"
import { RiMap2Line } from "react-icons/ri"
import deliveryMan from "../../assets/deliveryMan.png"
import mapa from "../../assets/map-1.png"
import shape from "../../assets/shape-bg.jpg"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import CustomCarousel from "../../components/slider/SimpleSlider"
import ScrollToTopButton from "./components/ScrollToTopButton"

export default function HomeScreen() {
    const mainRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.title = "Logplace – Logística Inteligente"
    }, [])

    const [isVisible, setIsVisible] = useState(false)
    const [headerFixed, setHeaderFixed] = useState(false)

    const scrollToTop = () => {
        if (mainRef.current) {
            mainRef.current.scrollTo({ top: 0, behavior: "smooth" })
        }
    }

    const headerFixedTrue = () => {
        setHeaderFixed(true)
    }

    const headerFixedFalse = () => {
        setHeaderFixed(false)
    }

    const handleScroll = (event: any) => {
        const scrollTop = event.currentTarget.scrollTop

        if (scrollTop > 10) {
            setHeaderFixed(true)
        } else {
            setHeaderFixed(false)
        }

        if (scrollTop > 500) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        const ref = mainRef.current
        if (ref) {
            ref.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (ref) {
                ref.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    return (
        <div className="w-screen h-screen overflow-auto" ref={mainRef}>
            <Helmet>
                <title>Logplace – Logística Inteligente</title>
                <meta
                    name="description"
                    content="Oferecemos soluções logísticas sob medida para sua operação. Fulfillment, B2B, B2C com inteligência e tecnologia."
                />
                <meta
                    name="keywords"
                    content="logística, fulfillment, B2C, B2B, transporte, soluções logísticas, entregas, e-commerce"
                />
                <meta name="author" content="LogPlace" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta
                    property="og:title"
                    content="Logplace – Logística Inteligente"
                />
                <meta
                    property="og:description"
                    content="Somos líderes em soluções logísticas para o pequeno, médio e-commerce e grandes operações de varejo."
                />

                <meta property="og:url" content="https://logplace.com.br" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="flex flex-col bg-white">
                <ScrollToTopButton show={isVisible} scrollPage={scrollToTop} />
                <div className="h-[7rem]">
                    <Header
                        background={headerFixed ? " " : ""}
                        headerFixedTrue={headerFixedTrue}
                        headerFixedFalse={headerFixedFalse}
                    />
                </div>
                <div className="h-full">
                    <CustomCarousel />
                </div>
                <div className="h-[75rem] md:h-[90rem] lg:h-[34rem] flex flex-col space-y-4 lg:space-y-10 bg-white justify-center items-center lg:space-x-5 lg:px-44">
                    <div className="flex justify-center w-1/2">
                        <p className="text-4xl font-medium text-center">
                            Oferecemos soluções logísticas sob medida para sua
                            operação
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full space-y-2 lg:flex-row lg:space-x-5">
                        <div className="flex flex-col items-center justify-between p-4 text-center bg-white rounded-sm shadow-lg h-72 w-80 lg:h-80 lg:w-96">
                            <div className="flex items-center justify-center flex-1">
                                <div className="flex items-center justify-center w-20 h-20 bg-red-200 rounded-full">
                                    <BsTruck size={50} color="#f74747" />
                                </div>
                            </div>
                            <div className="flex items-end justify-center flex-1">
                                <p className="text-xl font-medium">
                                    Fulfillment
                                </p>
                            </div>
                            <div className="flex items-center justify-center flex-2">
                                <p className="text-sm font-normal">
                                    Fulfillment é o processo logístico e que
                                    permite entregar produtos a clientes no
                                    e-commerce. Ele abrange desde o
                                    processamento do pedido até a separação no
                                    estoque, embalagem, entrega e pós-venda.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-between p-4 text-center bg-white rounded-sm shadow-lg h-72 w-80 lg:h-80 lg:w-96">
                            <div className="flex items-center justify-center flex-1">
                                <div className="flex items-center justify-center w-20 h-20 bg-red-200 rounded-full">
                                    <BsPerson size={50} color="#f74747" />
                                </div>
                            </div>
                            <div className="flex items-end justify-center flex-1">
                                <p className="text-xl font-medium">B2C</p>
                            </div>
                            <div className="flex items-center justify-center flex-2">
                                <p className="text-sm font-normal">
                                    O foco principal é a entrega de produtos
                                    diretamente aos consumidores, em vez de
                                    outras empresas. Isso inclui itens comprados
                                    online, em lojas físicas ou por meio de
                                    vários outros canais.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-between p-4 text-center bg-white rounded-sm shadow-lg h-72 w-80 lg:h-80 lg:w-96">
                            <div className="flex items-center justify-center flex-1">
                                <div className="flex items-center justify-center w-20 h-20 bg-red-200 rounded-full">
                                    <BsSuitcaseLg size={50} color="#f74747" />
                                </div>
                            </div>
                            <div className="flex items-end justify-center flex-1">
                                <p className="text-xl font-medium">B2B</p>
                            </div>
                            <div className="flex items-center justify-center flex-2">
                                <p className="text-sm font-normal">
                                    O principal foco é a entrega de produtos,
                                    suprimentos ou mercadorias de uma empresa
                                    para outra. Isso pode incluir
                                    matérias-primas, produtos acabados,
                                    componentes, vários tipos de insumos, etc.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-[50rem] lg:h-[38rem] xl:h-[40rem] flex flex-row lg:space-x-10 bg-white lg:px-48 lg:py-10">
                    <div className="items-end justify-end hidden h-full bg-white xl:w-1/2 xl:flex">
                        <img
                            className="h-[100%] w-[100%] object-contain"
                            src={deliveryMan}
                            alt="entregador"
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center h-full p-4 space-y-2 xl:w-1/2 xl:justify-around xl:space-y-4">
                        <div className="space-y-2 xl:space-y-0">
                            <p className="font-medium text-red-500">
                                ____ Sobre Nós
                            </p>
                            <p className="text-4xl font-medium text-primary">
                                Inteligência e Tecnologia são sinônimos da
                                LogPlace.
                            </p>
                            <p>
                                Com quase 20 anos de experiência em entregas
                                para 100% do território nacional, somos líderes
                                no atendimento ao pequeno, médio e-commerce e
                                gigantes do varejo. Também traçamos operações
                                complexas de indústria e governo.
                            </p>
                        </div>
                        <div className="flex flex-row w-full p-2 bg-white rounded-lg h-1/6 lg:h-2/6 2xl:h-2/6 drop-shadow-lg group">
                            <div className="flex items-center justify-center flex-2 xl:flex-1 hover:bg-transparent">
                                <div className="flex items-center justify-center w-20 h-20 transition duration-500 ease-in-out bg-red-200 rounded-full lg:w-16 lg:h-16 group-hover:bg-red-600">
                                    <AiOutlineGlobal
                                        size={45}
                                        className="text-red-600 transition duration-500 ease-in-out group-hover:text-white"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center flex-5 xl:flex-4">
                                <p className="text-xl font-bold text-primary">
                                    Unidades Próprias
                                </p>
                                <p>
                                    Nossas unidades próprias espalhadas pelo
                                    Nordeste garantem uma distribuição fluida e
                                    descomplicada.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-row w-full p-2 bg-white rounded-lg h-1/6 lg:h-2/6 2xl:h-2/6 drop-shadow-lg group">
                            <div className="flex items-center justify-center flex-2 xl:flex-1">
                                <div className="flex items-center justify-center w-20 h-20 transition duration-500 ease-in-out bg-red-200 rounded-full lg:w-16 lg:h-16 group-hover:bg-red-600">
                                    <RiMap2Line
                                        size={45}
                                        className="text-red-600 transition duration-500 ease-in-out group-hover:text-white"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center flex-5 xl:flex-4">
                                <p className="text-xl font-bold text-primary">
                                    Malha de Veículos
                                </p>
                                <p>
                                    Mais de 900 veículos garantem a qualidade e
                                    rapidez da nossa operação.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative h-[75rem] lg:h-[50rem] xl:h-[30rem] flex flex-row justify-center">
                    <img
                        src={shape}
                        className="absolute top-0 left-0 object-cover w-full h-full"
                    />
                    <div className="h-[70rem] lg:h-[40rem] xl:h-[30rem]  flex flex-row xl:space-x-10 lg:px-48 py-10 justify-center">
                        <img
                            src={mapa}
                            className="absolute z-10 object-contain w-2/3 h-full md:w-full lg:w-2/3 max-h-96"
                        />
                        <div className="z-20 flex flex-col items-center justify-center p-4 m-0 space-y-5 lg:p-0 lg:space-y-0">
                            <div className="flex flex-col space-y-4">
                                <p className="text-sm font-semibold text-center text-red-500">
                                    SOMOS ESPECIALISTA EM TRANSPORTE
                                </p>
                                <p className="text-4xl font-medium text-center text-primary">
                                    Juntos, superamos seus
                                    <br /> desafios logísticos.
                                </p>
                            </div>
                            <div className="flex flex-col items-center justify-center w-full h-full grid-cols-2 gap-2 space-y-2 lg:flex-row lg:items-end lg:p-4 lg:justify-around lg:space-x-5">
                                <div className="flex flex-col w-56 h-56 transition duration-300 ease-in-out bg-white rounded-lg hover:bg-blue-950 hover:text-white group">
                                    <div className="flex items-center justify-center flex-1">
                                        <div className="flex items-center justify-center w-20 h-20 transition duration-300 ease-in-out bg-blue-700 rounded-full group-hover:bg-orange-600">
                                            <FaCheck size={36} color="#fff" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center flex-1">
                                        <p className="text-2xl font-bold transition duration-300 ease-in-out text-emerald-700 group-hover:text-emerald-400">
                                            1Mi
                                        </p>
                                        <p className="font-semibold text-gray-700 group-hover:text-white">
                                            Entregas Realizadas
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col w-56 h-56 transition duration-300 ease-in-out bg-white rounded-lg hover:bg-blue-950 hover:text-white group">
                                    <div className="flex items-center justify-center flex-1">
                                        <div className="flex items-center justify-center w-20 h-20 transition duration-300 ease-in-out bg-blue-700 rounded-full group-hover:bg-orange-600">
                                            <FaRegHandshake
                                                size={40}
                                                color="#fff"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center flex-1">
                                        <p className="text-2xl font-bold transition duration-300 ease-in-out text-emerald-700 group-hover:text-emerald-400">
                                            12500
                                        </p>
                                        <p className="font-semibold text-gray-700 group-hover:text-white">
                                            Clientes Satisfeitos
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col w-56 h-56 transition duration-300 ease-in-out bg-white rounded-lg hover:bg-blue-950 hover:text-white group">
                                    <div className="flex items-center justify-center flex-1">
                                        <div className="flex items-center justify-center w-20 h-20 transition duration-300 ease-in-out bg-blue-700 rounded-full group-hover:bg-orange-600">
                                            <GrAchievement
                                                size={36}
                                                color="#fff"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center flex-1">
                                        <p className="text-2xl font-bold transition duration-300 ease-in-out text-emerald-700 group-hover:text-emerald-400">
                                            30+
                                        </p>
                                        <p className="font-semibold text-gray-700 group-hover:text-white">
                                            Prêmios Vencidos
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col w-56 h-56 transition duration-300 ease-in-out bg-white rounded-lg hover:bg-blue-950 hover:text-white group">
                                    <div className="flex items-center justify-center flex-1">
                                        <div className="flex items-center justify-center w-20 h-20 transition duration-300 ease-in-out bg-blue-700 rounded-full group-hover:bg-orange-600">
                                            <GrUserAdd size={36} color="#fff" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center flex-1">
                                        <p className="text-2xl font-bold transition duration-300 ease-in-out text-emerald-700 group-hover:text-emerald-400">
                                            450+
                                        </p>
                                        <p className="font-semibold text-gray-700 group-hover:text-white">
                                            Colaboradores
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
