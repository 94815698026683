import { Route, Routes } from "react-router-dom"
import EnterpriseScreen from "../screens/enterprise/EnterpriseScreen"
import HomeScreen from "../screens/home/HomeScreen"
import ServicesScreen from "../screens/services/ServicesScreen"
import TrackingScreen from "../screens/tracking/TrackingScreen"
import AggregateScreen from "../screens/workwithus/Aggregate"
import CollaboratorScreen from "../screens/workwithus/Collaborator"

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/rastreamento" element={<TrackingScreen />} />
            <Route path="/servicos" element={<ServicesScreen />} />
            <Route path="/aempresa" element={<EnterpriseScreen />} />
            <Route path="/trabalheconosco" element={<EnterpriseScreen />} />
            <Route
                path="/trabalheconosco/colaborador"
                element={<CollaboratorScreen />}
            />
            <Route
                path="/trabalheconosco/agregado"
                element={<AggregateScreen />}
            />
            <Route path="*" element={<HomeScreen />} />
        </Routes>
    )
}

export default AppRoutes
